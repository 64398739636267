import React, { ReactElement } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import Moment from 'react-moment';
import Alert from '../../../../../components/Alert';
import { CSSProperties } from '@mui/styles';
import { TableSortLabel, Chip } from '@mui/material';
import CustomerListFilters from '../../../../../models/list-filters/CustomerListFilters';
import Customer from '../../../../../models/customer/Customer';
import { Link } from 'react-router-dom';
import Paginator from '../../../../../components/Paginator';
import TableSubCell from '../../../../../components/TableSubCell';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import clsx from 'clsx';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

type HeadRow = {
    id: string;
    active: boolean;
    sortable: boolean;
    direction?: 'desc' | 'asc';
    label: string;
    subHead?: HeadRow[];
};

interface Props {
    rows: Customer[];
    rowsTotal: number;
    filters: CustomerListFilters;
    onFilterChange: (
        filterId: keyof CustomerListFilters,
        filterValue: any
    ) => void;
    onPaginationChange: (page: number, limit: number) => void;
    onSortByChange: (fieldId: string, direction: HeadRow['direction']) => void;
}

const actionButtonStyles: CSSProperties = {
    display: 'inline-block'
};

const actionCustomerContainer: CSSProperties = {
    minWidth: '40px',
    width: '40px'
};

const columnsStyles: CSSProperties = {
    whiteSpace: 'nowrap',
    verticalAlign: 'text-top',
    paddingTop: '12px'
};

const containerTableStyle: CSSProperties = {
    overflowX: 'auto',
    width: '100%'
};

const multipleHeadStyle: CSSProperties = {
    width: '100%',
    marginTop: '6px'
};

const autoPaymentDisabled: CSSProperties = {
    color: 'orange'
};

const autoPaymentEnabled: CSSProperties = {
    color: 'green'
};

const sortStyles: CSSProperties = {
    display: 'block'
};

const chipStyles: CSSProperties = {
    height: '16px',
    marginRight: '4px',
    marginBottom: '4px',
    fontSize: '10px'
};

export default function ListItems(props: Props): ReactElement {
    let headRows: HeadRow[] = [
        { id: 'createdAt', active: true, sortable: true, label: 'Created On' },
        {
            id: 'lastInvoiceDate',
            active: false,
            sortable: true,
            label: 'Last invoice on'
        },
        {
            id: 'name',
            active: false,
            sortable: true,
            label: 'Customer name'
        },

        { id: 'env', active: false, sortable: false, label: 'Environment' },
        {
            id: 'planInfo',
            active: false,
            sortable: true,
            label: 'Plan info',
            subHead: [
                {
                    id: 'planId',
                    active: false,
                    sortable: true,
                    label: 'Current plan'
                },
                {
                    id: 'preassignedPlanInfo.planId',
                    active: false,
                    sortable: false,
                    label: 'Preassigned plan'
                },
                {
                    id: 'billingInfo.analyticsTier',
                    active: false,
                    sortable: true,
                    label: 'Analytics Tier'
                }
            ]
        },
        {
            id: 'paymentInfo.paymentMethod',
            active: false,
            sortable: true,
            label: 'Payment method'
        },
        {
            id: 'clientInfo.company',
            active: false,
            sortable: true,
            label: 'Company'
        },
        {
            id: 'clientInfo.cif',
            active: false,
            sortable: true,
            label: 'Tax ID'
        },
        { id: 'tags', active: false, sortable: false, label: 'Tags' },
        {
            id: 'automaticInvoiceChargeDisabled',
            active: false,
            sortable: true,
            label: 'Auto charge'
        },
        { id: 'actions', active: false, sortable: false, label: 'Actions' }
    ];

    if (props.filters.sortBy) {
        headRows = headRows.map((row: HeadRow) => {
            row.active = row.id === props.filters.sortBy;
            if (row.subHead) {
                row.subHead = row.subHead.map((subHead: HeadRow) => {
                    subHead.active = subHead.id === props.filters.sortBy;
                    if (subHead.active && props.filters.direction) {
                        subHead.direction = props.filters.direction;
                    }
                    return subHead;
                });
            }
            if (row.active && props.filters.direction) {
                row.direction = props.filters.direction;
            }
            return row;
        });
    }

    const handleSortBy = (fieldId: string, subFieldId?: string) => {
        let direction: HeadRow['direction'] = 'asc';
        const sortKey = subFieldId ? subFieldId : fieldId;
        if (props.filters.sortBy === sortKey && props.filters.direction) {
            direction = props.filters.direction === 'asc' ? 'desc' : 'asc';
        }
        props.onSortByChange(sortKey, direction);
    };

    return (
        <div style={{ width: '100%' }}>
            <ToggleButtonGroup
                size='small'
                value={props.filters.environment}
                style={{ position: 'absolute', marginTop: '15px' }}
                onChange={(event, value) =>
                    props.onFilterChange('environment', value)
                }
            >
                {[
                    'eu1',
                    'eu2',
                    'eu3',
                    'eu4',
                    'eu5',
                    'eu6',
                    'eu7',
                    'am1',
                    'cl1'
                ].map((env, index) => (
                    <ToggleButton key={index + 1} value={env}>
                        {env.toUpperCase()}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
            <Paginator
                page={props.filters.page}
                rowsPerPage={props.filters.rowsPerPage}
                rowsTotal={props.rowsTotal}
                onPaginationChange={props.onPaginationChange}
            ></Paginator>
            {props.rows.length > 0 && (
                <div style={containerTableStyle}>
                    <Table size='small' className={'filtered-list'}>
                        <TableHead>
                            <TableRow>
                                {headRows.map(row => {
                                    if (row.sortable) {
                                        return (
                                            <TableCell
                                                key={row.id}
                                                sortDirection={
                                                    row.direction
                                                        ? row.direction
                                                        : false
                                                }
                                                style={columnsStyles}
                                            >
                                                {row.subHead &&
                                                    row.subHead.map(
                                                        (head, i) => (
                                                            <TableSortLabel
                                                                key={i}
                                                                style={
                                                                    sortStyles
                                                                }
                                                                active={
                                                                    head.active
                                                                }
                                                                direction={
                                                                    head.direction
                                                                }
                                                                onClick={() =>
                                                                    handleSortBy(
                                                                        row.id,
                                                                        head.id
                                                                    )
                                                                }
                                                            >
                                                                {head.label}
                                                            </TableSortLabel>
                                                        )
                                                    )}

                                                {!row.subHead && (
                                                    <TableSortLabel
                                                        style={sortStyles}
                                                        active={row.active}
                                                        direction={
                                                            row.direction
                                                        }
                                                        onClick={() =>
                                                            handleSortBy(row.id)
                                                        }
                                                    >
                                                        {row.label}
                                                    </TableSortLabel>
                                                )}
                                            </TableCell>
                                        );
                                    } else {
                                        return (
                                            <TableCell
                                                key={row.id}
                                                style={columnsStyles}
                                            >
                                                <div style={multipleHeadStyle}>
                                                    {row.label}
                                                </div>
                                            </TableCell>
                                        );
                                    }
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.rows.map(row => (
                                <TableRow
                                    key={row._id}
                                    className={clsx({
                                        deleted: row.deleted
                                    })}
                                >
                                    <TableCell>
                                        <Moment
                                            date={row.createdAt}
                                            format='DD/MM/YYYY'
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {row.lastInvoiceDate && (
                                            <Moment
                                                date={row.lastInvoiceDate}
                                                format='DD/MM/YYYY'
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.env}</TableCell>
                                    <TableCell style={columnsStyles}>
                                        {row.planName}
                                        <TableSubCell>
                                            {row.preassignedPlanName
                                                ? row.preassignedPlanName
                                                : '-'}
                                        </TableSubCell>

                                        <TableSubCell>
                                            {row.billingInfo.analyticsTier}
                                        </TableSubCell>
                                    </TableCell>
                                    <TableCell>
                                        {row.paymentInfo.paymentMethod}
                                    </TableCell>
                                    <TableCell>
                                        {row.clientInfo.company}
                                    </TableCell>
                                    <TableCell>{row.clientInfo.cif}</TableCell>
                                    <TableCell>
                                        {row.tags &&
                                            row.tags.map((tag, i) => (
                                                <Chip
                                                    key={i}
                                                    style={chipStyles}
                                                    label={tag}
                                                    color='primary'
                                                />
                                            ))}
                                    </TableCell>
                                    <TableCell>
                                        {row.automaticInvoiceChargeDisabled ? (
                                            <label style={autoPaymentDisabled}>
                                                Disabled
                                            </label>
                                        ) : (
                                            <label style={autoPaymentEnabled}>
                                                Enabled
                                            </label>
                                        )}
                                    </TableCell>
                                    <TableCell align='right'>
                                        <div
                                            className='actions'
                                            style={actionCustomerContainer}
                                        >
                                            {!row.deleted && (
                                                <Tooltip
                                                    title='Edit'
                                                    style={actionButtonStyles}
                                                >
                                                    <IconButton
                                                        color='primary'
                                                        aria-label='Edit'
                                                        component={Link}
                                                        to={`/customer/${row.uuid}/edit`}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            {row.deleted && (
                                                <Tooltip
                                                    title='View'
                                                    style={actionButtonStyles}
                                                >
                                                    <IconButton
                                                        color='inherit'
                                                        aria-label='View'
                                                        component={Link}
                                                        to={`/customer/${row.uuid}/edit`}
                                                    >
                                                        <RemoveRedEyeIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            )}
            {props.rows.length === 0 && (
                <Alert
                    visible={props.rows.length === 0}
                    variant='info'
                    style={{ marginTop: '65px' }}
                >
                    No items found
                </Alert>
            )}
        </div>
    );
}
